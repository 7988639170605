<template>
  <div id="app" data-app app-data="true">
    <sms-gateway-root/>
  </div>
</template>

<script>
import SMSGatewayRoot from './components/SMSGatewayRoot/SMSGatewayRoot.vue'

export default {
  name: 'App',
  components: {
    'sms-gateway-root': SMSGatewayRoot,
  }
}
</script>

<style>

</style>
