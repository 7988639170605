import Vue from 'vue'
import App from './App.vue'
import Vuetning from 'vuetning'
import "bootstrap/dist/css/bootstrap.css"
import axios from 'axios'
import Postmonger from 'vue2-postmonger';

Vue.use(Vuetning)
Vue.use(Postmonger)

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

Vue.config.productionTip = false

const backendUrl = process.env.VUE_APP_BACKEND_URL;

axios.defaults.baseURL = backendUrl;

new Vue({
  render: h => h(App),
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: { light: true },
  }),
}).$mount('#app')
