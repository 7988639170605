export default {
    data() {
        return {
            errorMessage: '',
            loading: true,
            fields: [],
        }
    },
    methods: {
        async loaded() {
            try {

                if (!this.eventDefinitionKey) {
                    throw new Error('Loaded called but event definition id is not found, please, attach Entry Source.');
                }
                const arg = this.activity.arguments.execute.inArguments[0];
                if(arg && arg.fields) {
                    const { fields } = arg;
                    if(fields && Object.keys(fields).length) {
                        this.fields = fields;
                    } else {
                        await this.setFields();
                    }
                } else {
                    await this.setFields();
                }

                this.onClickedNext(() => this.Save());

                await this.requestSchema();
                 
                this.loading = false;
            } catch (err) {
                console.error(err)
                this.errorMessage = err.message;
            }
        },
        async setFields() {
            this.fields = [
                {
                    "label": "",
                    "type": "text",
                    "value": "",
                    "propName": "ContactKey",
                    "hidden": true,
                    "contactKey": true
                },
                {
                    "label": "Phone number field name",
                    "type": "text",
                    "value": "",
                    "propName": "phone",
                    "binding": true,
                    "hint": `{{Event."${this.eventDefinitionKey}"."FIELD_NAME"}}`
                },
                {
                    "label": "Activity Name",
                    "type": "text",
                    "value": "",
                    "propName": "activityName",
                    "hidden": false,
                },
                {
                    "label": "SMS message text",
                    "type": "textarea",
                    "value": "",
                    "propName": "message",
                    "hidden": false,
                },
                
            ]
        },
        getFieldValue(field) {
            // if(field.type == 'dropdown') {
            //     return field.value && field.value.value ? field.value.value : '';
            // } else 
            if(field.type == 'multichoice') {
                return field.value && field.value.length ? field.value.map(el => el.value) : null;
            } else {
                return field.value;
            }
        },
        Save() {
            let p = {};
            
            this.fields.forEach(field => {
                //console.log('field contactKey', field.contactKey)
                if(field.contactKey) {
                    Object.assign(p, { [field.propName]: `{{Contact.Key}}` })
                } else if(field.hidden || field.binding) {
                    Object.assign(p, { [field.propName]: field.value })
                } else {
                    Object.assign(p, { [field.propName]: this.getFieldValue(field) })
                }
            })

            const payload = [{
                execute: p,
                fields: this.fields,
            }];

            const nameField = this.fields.find((field) => field.propName == 'activityName');
            if(nameField && nameField.value) {
                this.activity.name = nameField.value;
            }
            

            this.activity.metaData.isConfigured = true;
            this.activity.arguments.execute.inArguments = payload;

            this.update();
            this.destroy();

        }
    },
}

